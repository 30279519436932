
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button"

class AdminBroadcastsConfirmSend extends React.Component {
	state = {
		cancelMessage: '',
		formValue: '',
		isConfirming: false
	}

	render() {
		const {handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Send broadcast now?</h2>
				<p><b>Please note:</b> this action is permanent and cannot be undone.</p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Back</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming}>Send Now</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = async () => {
		const {handleConfirm} = this.props

		this.setState({
			isConfirming: true
		})

		handleConfirm().then(() => {
			this.setState({
				isConfirming: false
			})
		})
	}
}

export default AdminBroadcastsConfirmSend
