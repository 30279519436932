
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import AdminBroadcastsTableRow from "./adminBroadcastsTableRow"
import TableSort from '../tableSort'

class AdminBroadcastsTable extends React.Component {
	render() {
		const {activeFilters, items, emptyMessage, handleOpenView, updateStateItems, getBroadcasts} = this.props

		let classNames = Classnames([
			'admin-table admin-table--broadcasts wrap--scrollx',
		])

		return (
			<div className={classNames}>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th>Name <TableSort fieldName="name" refreshData={getBroadcasts}/></th>
							<th>Created <TableSort fieldName="created_at" refreshData={getBroadcasts}/></th>
							{activeFilters?.filter?.status_id > "1" && <th>Scheduled For <TableSort fieldName="deliver_at" refreshData={getBroadcasts}/></th>}
							{activeFilters?.filter?.status_id === "3" && <th>Delivered At <TableSort fieldName="delivered_at" refreshData={getBroadcasts}/></th>}
							<th className="actions"><span className="sr-only">Actions</span></th>
						</tr>
					</thead>
					<tbody>
					{
						items?.length
							? items.map((item) => <AdminBroadcastsTableRow
								key={item.id}
								item={item}
								handleOpenView={handleOpenView}
								updateStateItems={updateStateItems} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminBroadcastsTable
