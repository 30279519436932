
// Libraries
import * as React from 'react'
import _ from 'lodash'
import qs from 'qs'

// Services
import ApiService from '../../../services/api'
import BroadcastsService from '../../../services/broadcasts'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminBroadcastsTableHeader from '../../../components/admin/broadcasts/adminBroadcastsTableHeader'
import AdminBroadcastsTable from '../../../components/admin/broadcasts/adminBroadcastsTable'
import AdminBroadcastsView from '../../../components/admin/broadcasts/adminBroadcastsView'
import AdminBroadcastsEdit from '../../../components/admin/broadcasts/adminBroadcastsEdit'
import AdminBroadcastsSchedule from '../../../components/admin/broadcasts/adminBroadcastsSchedule'
import AdminBroadcastsConfirmUnschedule from "../../../components/admin/broadcasts/adminBroadcastsConfirmUnschedule"
import AdminBroadcastsConfirmSend from "../../../components/admin/broadcasts/adminBroadcastsConfirmSend"
import AdminBroadcastsConfirmDelete from "../../../components/admin/broadcasts/adminBroadcastsConfirmDelete"
import Pagination from "../../../components/pagination"
import {faBullhorn} from '@fortawesome/pro-solid-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminBroadcastsIndexPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {}
		},
		items: [],
		pagination: [],
		emptyMessage: 'Retrieving broadcast data ...',
		selectedItem: {},
		previewContent: null,
		modalViewShow: false,
		modalEditShow: false,
		modalScheduleShow: false,
		modalConfirmUnscheduleShow: false,
		modalConfirmSendShow: false,
		modalConfirmDeleteShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, items, selectedItem, pagination, emptyMessage, modalViewShow, modalEditShow,
			modalScheduleShow, modalConfirmUnscheduleShow, modalConfirmSendShow, modalConfirmDeleteShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-broadcasts nav-blue-half">
				<Seo title="Broadcasts Management" />
				<div className="admin-table__wrap">
					<AdminBroadcastsTableHeader
						titleText="Broadcasts Management" titleIcon={faBullhorn}
						searchValue={activeFilters.search} searchHandler={_.debounce(this.handleSearchChange, 500)} />

					<p className='filters-status'>
						Filter by:
						<a href="/admin/broadcasts/" className={activeFilters?.filter?.status_id === '1' && 'active'}>Draft</a>
						<a href="/admin/broadcasts/?filter[status_id]=2" className={activeFilters?.filter?.status_id === '2' && 'active'}>Scheduled</a>
						<a href="/admin/broadcasts/?filter[status_id]=3" className={activeFilters?.filter?.status_id === '3' && 'active'}>Complete</a>
					</p>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminBroadcastsTable
						activeFilters={activeFilters}
						getBroadcasts={this.getItems}
						items={items}
						emptyMessage={emptyMessage}
						handleOpenView={this.handleOpenView} />

					{modalViewShow && <AdminBroadcastsView
						item={selectedItem}
						handleClose={() => this.setState({modalViewShow: false})}
						handleOpenEdit={this.handleOpenEdit}
						handleOpenSchedule={this.handleOpenSchedule}
						handleOpenPreview={this.handleOpenPreview}
						handleConfirmUnschedule={this.handleConfirmUnschedule}
						handleConfirmSend={this.handleConfirmSend}
						handleConfirmDelete={this.handleConfirmDelete} />}

					{modalEditShow && <AdminBroadcastsEdit
						item={selectedItem}
						handleClose={() => this.setState({
							modalEditShow: false,
							modalViewShow: true
						})}
						handleEditComplete={this.handleEditComplete} />}

					{modalScheduleShow && <AdminBroadcastsSchedule
						item={selectedItem}
						handleClose={() => this.setState({
							modalScheduleShow: false,
							modalViewShow: true
						})}
						handleScheduleComplete={this.handleScheduleComplete} />}

					{modalConfirmUnscheduleShow && <AdminBroadcastsConfirmUnschedule
						item={selectedItem}
						handleClose={() => this.setState({modalConfirmUnscheduleShow: false})}
						handleConfirm={this.handleProcessUnschedule} />}

					{modalConfirmSendShow && <AdminBroadcastsConfirmSend
						item={selectedItem}
						handleClose={() => this.setState({modalConfirmSendShow: false})}
						handleConfirm={this.handleProcessSend} />}

					{modalConfirmDeleteShow && <AdminBroadcastsConfirmDelete
						item={selectedItem}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={this.handleProcessDelete} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

		Promise.all([
			this.getItems({...activeFilters, ...searchParams}, false)
				.catch(() => addNotification('There was an error fetching the broadcasts.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters: {...activeFilters, ...searchParams},
				emptyMessage: "No broadcasts found, try adjusting your filters"
			})
		})
	}

	getItems = (queryParams = {}) => {
		const apiService = new ApiService()
		queryParams = {...this.state.activeFilters, ...queryParams}
		queryParams['paginate'] = 1
		if (!queryParams['filter']['status_id']) {
			queryParams['filter']['status_id'] = "1"
		}
		return apiService.get('broadcasts', {}, queryParams).then( (response) => {
			this.setState({
				items: response.data.data,
				pagination: response.data,
				activeFilters: queryParams
			})
		}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getItems(activeFilters)
				.catch(() => addNotification('There was an error filtering the broadcasts.', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getItems(activeFilters)
				.catch(() => addNotification('There was an error filtering the broadcasts.', 'error'))
		})
	}

	handleOpenView = (itemId) => {
		const apiService = new ApiService()
		return apiService.get(`broadcasts/${itemId}`)
			.then( (response) => {
				this.updateStateSelectedItem(response.data)
				this.setState({
					modalViewShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenEdit = (itemId) => {
		const apiService = new ApiService()
		return apiService.get(`broadcasts/${itemId}`)
			.then( (response) => {
				this.updateStateSelectedItem(response.data)
				this.setState({
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenSchedule = (itemId) => {
		const apiService = new ApiService()
		return apiService.get(`broadcasts/${itemId}`)
			.then( (response) => {
				this.updateStateSelectedItem(response.data)
				this.setState({
					modalScheduleShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenPreview = (itemId) => {
		const broadcastsService = new BroadcastsService()
		return broadcastsService.preview(itemId)
			.then( (response) => {
				if (response.success) {

					const win = window.open(
						"",
						"broadcastPreview",
						"toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=375,height=650,top=50,left=50")
					win.document.write(response.data.html)
				}
			})
			.catch(err => console.error(err))
	}

	handleEditComplete = (item) => {
		this.updateStateSelectedItem(item)
		this.updateStateItems(item)
		this.setState({
			modalEditShow: false,
			modalViewShow: true
		})
	}

	handleScheduleComplete = (item) => {
		this.updateStateSelectedItem(item)
		this.updateStateItems(item, true)
		this.setState({
			modalScheduleShow: false,
			modalViewShow: true
		})
	}

	handleConfirmSend = () => {
		this.setState({
			modalConfirmSendShow: true
		})
	}

	handleConfirmUnschedule = () => {
		this.setState({
			modalConfirmUnscheduleShow: true
		})
	}

	handleConfirmDelete = () => {
		this.setState({
			modalConfirmDeleteShow: true
		})
	}

	handleProcessUnschedule = () => {
		const {selectedItem} = this.state
		const {addNotification} = this.context

		const broadcastsService = new BroadcastsService()
		return broadcastsService.unschedule(selectedItem.id)
			.then( (response) => {
				if (response.success) {
					addNotification('Broadcast unscheduled', 'success')

					this.updateStateSelectedItem(response.data)
					this.updateStateItems(selectedItem, true)
					this.setState({
						modalViewShow: true,
						modalConfirmUnscheduleShow: false
					})
				} else {
					addNotification('Unable to unschedule broadcast', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	handleProcessSend = () => {
		const {selectedItem} = this.state
		const {addNotification} = this.context

		const broadcastsService = new BroadcastsService()
		return broadcastsService.send(selectedItem.id)
			.then( (response) => {
				if (response.data?.success) {
					addNotification(response.data?.message, 'success')
					this.setState({
						modalViewShow: false,
						modalConfirmSendShow: false
					})
				} else {
					addNotification('Unable to send broadcast', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	handleProcessDelete = () => {
		const {selectedItem} = this.state
		const {addNotification} = this.context

		const broadcastsService = new BroadcastsService()
		return broadcastsService.delete(selectedItem.id)
			.then( (response) => {
				if (response.data?.deleted) {
					addNotification('Broadcast deleted', 'success')

					this.updateStateItems(selectedItem, true)
					this.setState({
						modalViewShow: false,
						modalConfirmDeleteShow: false
					})
				} else {
					addNotification('Unable to delete broadcast', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	updateStateSelectedItem = (event) => {
		this.setState(prevState => ({
			selectedItem: {...prevState.selectedItem, ...event}
		}))
	}

	updateStateItems = (item, remove = false) => {
		const {items} = this.state

		// update events state
		items.forEach((existingItem, key) => {
			if (existingItem.id === item.id) {
				if (remove) {
					items.splice(key, 1)
				}
				else {
					items[key] = {...existingItem, ...item}
				}
			}
		})

		this.setState({items})
	}
}

export default AdminBroadcastsIndexPage
