
// Libraries
import * as React from 'react'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import InputFactory from "../../inputFactory"
import Button from '../../button'
import {faSearch, faPlus} from '@fortawesome/pro-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AdminBroadcastsTableHeader extends React.Component {

	render() {
		const {titleIcon, titleText, infoText, searchValue, searchHandler} = this.props

		return <div className="admin-table__header">
			<div className="column column--title">
				<h1><FontAwesomeIcon icon={titleIcon} /> {titleText}</h1>
				{(infoText && infoText !== '') && <p>{infoText}</p>}
			</div>
			<div className="column column--search">
				<form>
					<FontAwesomeIcon icon={faSearch} className="search-icon" />
					<InputFactory
						type="search"
						name="search"
						placeholder="Search"
						value={searchValue}
						onChange={searchHandler}
						onKeyDown={this.handleOnKeyDown}
					/>
				</form>
				<Button to="/admin/broadcasts/new" iconOnly data-tip data-for="tooltip-new-broadcast">
					<FontAwesomeIcon icon={faPlus} title="New broadcast icon" />
					<span className="sr-only">New Broadcast</span>
				</Button>
				<ReactTooltip id="tooltip-new-broadcast" place="top" backgroundColor="#005EB8" textColor="#fff">
					<span>New Broadcast</span>
				</ReactTooltip>
			</div>
		</div>
	}

	handleOnKeyDown = (keyDownEvent) => {
		if(keyDownEvent.keyCode === 13) {
			keyDownEvent.preventDefault();
			return false;
		}
	}
}

export default AdminBroadcastsTableHeader
