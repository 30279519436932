
// Libraries
import * as React from 'react'
import moment from "moment"
import Classnames from 'classnames'

// Components
import Modal from "../../modal"
import AdminBroadcastsViewRow from "./adminBroadcastsViewRow"
import Button from "../../button"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrashAlt, faEye, faCalendarEdit, faCalendarTimes, faPaperPlane} from '@fortawesome/pro-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AdminBroadcastsView extends React.Component {
	render() {
		const {item, handleClose, handleOpenEdit, handleOpenSchedule, handleConfirmUnschedule,
			handleConfirmSend, handleOpenPreview, handleConfirmDelete} = this.props

		let classNames = Classnames([
			'admin-view',
			'admin-view--broadcast',
			{
				'admin-view--broadcast-draft': item.status_id === 1,
			},
			{
				'admin-view--broadcast-scheduled': item.status_id === 2,
			},
			{
				'admin-view--broadcast-complete': item.status_id === 3,
			},
		])

		const recipientList = []
		item.recipientTags?.forEach((tag) => {
			const userCount = tag.recipient_users_count;
			recipientList.push(<>Tag: {tag.name.en} ({userCount} user{userCount > 1 && 's'})<br/></>)
		})
		item.recipientRecipientables?.forEach((recipientable) => {
			const userCount = recipientable.recipient_users_count;
			if (recipientable.recipientable_type?.endsWith('User')) {
				recipientList.push(<>User: {recipientable.recipientable.email} ({userCount} user{userCount > 1 && 's'})<br/></>)
			}
			else if (recipientable.recipientable_type?.endsWith('Event')) {
				recipientList.push(<>Event: {recipientable.recipientable.name} ({userCount} user{userCount > 1 && 's'})<br/></>)
			}
			else if (recipientable.list_email) {
				recipientList.push(<>List: {recipientable.list_email}<br/></>)
			}
		})

		const disableSendSchedule = (!item.content || item.recipient_count < 1)

		return <Modal handleClose={handleClose} modalRight>
			<div className={classNames}>
				<div className="admin-view__header">
					<div className="column">
						<h3>{item.name}</h3>
						<p>Created: {moment(item.created_at).format('DD/MM/YY')}</p>
					</div>
					<div className="column">
						<ul className="admin-view__header-nav">
							{item.status_id === 1 && <li><Button onClick={() => handleOpenEdit(item.id)} iconOnly data-tip data-for="tooltip-edit-broadcast">
								<FontAwesomeIcon icon={faEdit} title="Edit icon" />
								<span className="sr-only">Delete</span>
							</Button></li>}
							{item.status_id < 3 && <li><span data-tip={true} data-for="tooltip-schedule-broadcast">
								<Button onClick={() => handleOpenSchedule(item.id)} iconOnly disabled={disableSendSchedule}>
									<FontAwesomeIcon icon={faCalendarEdit} title="Schedule icon" />
									<span className="sr-only">Schedule</span>
								</Button>
							</span></li>}
							{item.status_id === 2 && <li><Button onClick={() => handleConfirmUnschedule(item.id)} iconOnly danger data-tip data-for="tooltip-unschedule-broadcast">
								<FontAwesomeIcon icon={faCalendarTimes} title="Unschedule icon" />
								<span className="sr-only">Unschedule</span>
							</Button></li>}
							{item.status_id < 3 && <li><span data-tip={true} data-for="tooltip-send-broadcast">
								<Button onClick={() => handleConfirmSend(item.id)} iconOnly disabled={disableSendSchedule}>
									<FontAwesomeIcon icon={faPaperPlane} title="Send icon" />
									<span className="sr-only">Send</span>
								</Button>
							</span></li>}
							<li><Button onClick={() => handleOpenPreview(item.id)} iconOnly data-tip data-for="tooltip-preview-broadcast">
								<FontAwesomeIcon icon={faEye} title="Preview icon" />
								<span className="sr-only">Preview</span>
							</Button></li>
							<li><Button onClick={() => handleConfirmDelete()} iconOnly danger data-tip data-for="tooltip-delete-broadcast">
								<FontAwesomeIcon icon={faTrashAlt} title="Delete icon" />
								<span className="sr-only">Delete</span>
							</Button></li>
						</ul>

						<ReactTooltip id="tooltip-edit-broadcast" place="top" backgroundColor="#005EB8" textColor="#fff">
							<span>Edit</span></ReactTooltip>
						<ReactTooltip id="tooltip-schedule-broadcast" place="top" backgroundColor="#005EB8" textColor="#fff">
							<span>{disableSendSchedule ? 'Blocked: Add recipients and/or content' : 'Schedule'}</span></ReactTooltip>
						<ReactTooltip id="tooltip-unschedule-broadcast" place="top" backgroundColor="#005EB8" textColor="#fff">
							<span>Unschedule</span></ReactTooltip>
						<ReactTooltip id="tooltip-send-broadcast" place="top" backgroundColor="#005EB8" textColor="#fff">
							<span>{disableSendSchedule ? 'Blocked: Add recipients and/or content' : 'Send'}</span></ReactTooltip>
						<ReactTooltip id="tooltip-preview-broadcast" place="top" backgroundColor="#005EB8" textColor="#fff">
							<span>Preview</span></ReactTooltip>
						<ReactTooltip id="tooltip-delete-broadcast" place="top" backgroundColor="#005EB8" textColor="#fff">
							<span>Delete</span></ReactTooltip>
					</div>
				</div>
				<div className="admin-view__info">
					<p><b>Subject</b> {item.subject}</p>
					<p><b>Status</b> {item.status ? item.status.name : '-'}</p>
					<p><b>Template</b> {item.template ? item.template.charAt(0).toUpperCase() + item.template.slice(1) : 'None (Custom HTML)'}</p>
					<p><b>Recipient Count</b> {item.recipient_count}</p>
					<p><b>Scheduled For</b> {item.deliver_at ? moment(item.deliver_at).format('DD/MM/YY HH:mm') : '-'}</p>
					{item.status_id === 3 && <>
						<p><b>Delivered At</b> {item.delivered_at ? moment(item.delivered_at).format('DD/MM/YY HH:mm') : '-'}</p>
					</>}
				</div>
				<div className="admin-view__view">
					<AdminBroadcastsViewRow label="Recipients" value={recipientList.length ? recipientList : 'None'} />
					{(item.status_id === 3 && item.stats) && <AdminBroadcastsViewRow label="Statistics" value={<>Accepted | {item.stats[0].accepted.total}<br/>
						Delivered | {item.stats[0].delivered.total}<br/>
						Failed | {item.stats[0].failed.temporary.total + item.stats[0].failed.permanent.total}<br/>
						Opened | {item.stats[0].opened.total}<br/>
						Clicked | {item.stats[0].clicked.total}<br/>
						Unsubscribed | {item.stats[0].unsubscribed.total}<br/>
						Complained | {item.stats[0].complained.total}</>} />}
				</div>
			</div>
		</Modal>
	}
}

export default AdminBroadcastsView
