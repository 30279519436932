
// Libraries
import * as React from 'react'
import moment from 'moment'

// Services
import BroadcastsService from '../../../services/broadcasts'

// Components
import Modal from '../../modal'
import AdminBroadcastsViewRow from './adminBroadcastsViewRow'
import InputFactory from '../../inputFactory'
import Button from '../../button'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminBroadcastsSchedule extends React.Component {
	state = {
		initialValues: {},
		inputValues: {},
		inputErrors: {},
		isSubmitting: false,
	}

	static contextType = NotificationContext

	render() {
		const {item, handleClose} = this.props
		const {inputValues, inputErrors, isSubmitting} = this.state

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--event">
				<div className="admin-form__header">
					<div className="column">
						<h3>Schedule: {item.name}</h3>
						<p>Created: {moment(item.created_at).format('DD/MM/YY')}</p>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit}>
						<div className="admin-form__field-group">
							<h2>Schedule</h2>
							<AdminBroadcastsViewRow
								label="Date"
								value={<InputFactory
									type="date"
									name="deliver_at_date"
									value={inputValues?.deliver_at_date}
									error={inputErrors?.deliver_at_date}
									onChange={this.handleInputChange}
								/>} />
							<AdminBroadcastsViewRow
								label="Time"
								value={<InputFactory
									type="time"
									name="deliver_at_time"
									value={inputValues?.deliver_at_time}
									error={inputErrors?.deliver_at_time}
									onChange={this.handleInputChange}
								/>} />
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	componentDidMount() {
		this.fetchTemplateOptions()

		const {item} = this.props
		const {inputValues} = this.state

		let deliver_at = moment();
		if (item.deliver_at) {
			deliver_at = moment(item.deliver_at)
		}
		inputValues.deliver_at_date = deliver_at.format('YYYY-MM-DD')
		inputValues.deliver_at_time = deliver_at.format('HH:mm')
	}

	async fetchTemplateOptions() {
		const broadcastsService = new BroadcastsService()

		const templatesResponse = await broadcastsService.getTemplateOptions()
		let templateOptions = []
		templatesResponse.data.forEach((template) =>  {
			templateOptions.push({
				label: template?.name.charAt(0).toUpperCase() + template?.name.slice(1),
				value: template?.name,
			})
		})
		templateOptions.push({
			label: 'None (Custom HTML)',
			value: null,
		})
		this.setState({
			templateOptions: templateOptions
		})
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (name === 'template') {
				this.setState({ showTinymce: value.value !== null })
			}

			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent = null) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {item, handleScheduleComplete} = this.props
		const {inputValues} = this.state
		const broadcastsService = new BroadcastsService()
		const {addNotification} = this.context

		// prepare form data
		if (inputValues['deliver_at_date']) {
			inputValues['deliver_at'] = `${inputValues['deliver_at_date']} ${inputValues['deliver_at_time']}`
		}
		const formData = JSON.stringify(inputValues)

		// submit user data
		await broadcastsService.schedule(item.id, formData)
			.then(async (response) => {
				if (response.success) {
					addNotification('Broadcast Scheduled', 'success')
					handleScheduleComplete(response.data)
				}
				else {
					const inputErrors = response.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminBroadcastsSchedule
