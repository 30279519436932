
// Libraries
import * as React from 'react'
import moment from "moment"
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminBroadcastsTableRow extends React.Component {

	static contextType = NotificationContext

	render() {
		const {item, handleOpenView} = this.props
		let classNames = Classnames([
			'admin-table__row',
			{
				'admin-table__row--draft': item.status_id === 1,
			},
			{
				'admin-table__row--scheduled': item.status_id === 2,
			},
			{
				'admin-table__row--complete': item.status_id === 3,
			},
		])

		return <tr className={classNames}>
			<td className="admin-table__cell--name">{item.name}</td>
			<td className="admin-table__cell--created">{moment(item.created_at).format('DD/MM/YY')}</td>
			{item.status_id > 1 && <td className="admin-table__cell--date-deliver">{item.deliver_at ? moment(item.deliver_at).format('DD/MM/YY HH:mm') : '-'}</td>}
			{item.status_id === 3 && <td className="admin-table__cell--date-delivered">{item.delivered_at ? moment(item.delivered_at).format('DD/MM/YY HH:mm') : '-'}</td>}
			<td className="admin-table__cell--actions">
				<button onClick={() => handleOpenView(item.id)}>
					<FontAwesomeIcon icon={faArrowRight} title="Edit icon" /><span className="sr-only"> View/Edit</span>
				</button>
			</td>
		</tr>
	}
}

export default AdminBroadcastsTableRow
