
// Libraries
import * as React from 'react'
import moment from 'moment'

// Services
import ApiService from '../../../services/api'
import BroadcastsService from '../../../services/broadcasts'

// Components
import Modal from '../../modal'
import AdminBroadcastsViewRow from './adminBroadcastsViewRow'
import InputFactory from '../../inputFactory'
import Button from '../../button'

// Context
import NotificationContext from '../../../contexts/notification'
import BroadcastRecipientManager from "../../broadcastRecipientManager";

class AdminBroadcastsEdit extends React.Component {
	state = {
		initialValues: {},
		inputValues: {
			recipients: []
		},
		inputErrors: {},
		isSubmitting: false,
		showTinymce: true,
		templateOptions: [],
	}

	static contextType = NotificationContext

	render() {
		const {item, handleClose} = this.props
		const {initialValues, inputValues, inputErrors, showTinymce, isSubmitting, templateOptions} = this.state

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--event">
				<div className="admin-form__header">
					<div className="column">
						<h3>Edit: {item.name}</h3>
						<p>Created: {moment(item.created_at).format('DD/MM/YY')}</p>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit}>
						<div className="admin-form__field-group">
							<h2>Broadcast</h2>
							<AdminBroadcastsViewRow
								label="Name"
								value={<InputFactory
									type="text"
									name="name"
									value={inputValues?.name}
									error={inputErrors?.name}
									onChange={this.handleInputChange}
								/>} />
						</div>
						<div className="admin-form__field-group">
							<h2>Content</h2>
							<AdminBroadcastsViewRow
								label="Template"
								value={<InputFactory
									type="select"
									name="template"
									value={inputValues?.template}
									error={inputErrors?.template}
									onChange={this.handleInputChange}
									options={templateOptions}
								/>} />
							<AdminBroadcastsViewRow
								label="Subject"
								value={<InputFactory
									type="text"
									name="subject"
									value={inputValues?.subject}
									error={inputErrors?.subject}
									onChange={this.handleInputChange}
								/>} />
							{showTinymce && <AdminBroadcastsViewRow
								label="Content Editor"
								value={<InputFactory
									type="tinymce"
									name="content"
									initialValue={initialValues?.content}
									value={inputValues?.content}
									error={inputErrors?.content}
									onChange={this.handleInputChange}
								/>} />}
							{!showTinymce && <AdminBroadcastsViewRow
								label="Custom HTML"
								value={<InputFactory
									type="textarea"
									name="content"
									value={inputValues?.content}
									error={inputErrors?.content}
									onChange={this.handleInputChange}
								/>} />}
						</div>
						<div className="admin-form__field-group">
							<h2>Recipients</h2>
							<AdminBroadcastsViewRow
								label="Select Recipients"
								value={<BroadcastRecipientManager
									values={inputValues?.recipients}
									error={inputErrors?.recipients}
									inputChange={this.handleInputChange}
									inputAdd={this.handleBroadcastRecipientAdd}
									inputRemove={this.handleBroadcastRecipientRemove}
								/>} />
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	componentDidMount() {
		this.fetchTemplateOptions()
		this.fetchRecipientOptions()

		const {item} = this.props
		const {initialValues, inputValues} = this.state

		initialValues.content = item.content

		inputValues.name = item.name
		if (item.template) {
			inputValues.template = {
				label: item.template.charAt(0).toUpperCase() + item.template.slice(1),
				value: item.template
			}
		}
		else {
			inputValues.template = {
				label: 'None (Custom HTML)',
				value: null
			}

			this.setState({ showTinymce: false })
		}
		inputValues.subject = item.subject
		inputValues.content = item.content

		item.recipientTags.forEach((tag) => {
			inputValues.recipients.push({
				type: 'tag',
				label: 'Tag: ' + tag.name.en,
				value: tag.name.en
			})
		})
		item.recipientRecipientables.forEach((recipientable) => {
			if (recipientable.recipientable_type?.endsWith('User')) {
				inputValues.recipients.push({
					type: 'user',
					label: 'User: ' + recipientable.recipientable.email,
					value: recipientable.recipientable.id
				})
			}
			else if (recipientable.recipientable_type?.endsWith('Event')) {
				inputValues.recipients.push({
					type: 'event',
					label: 'Event: ' + recipientable.recipientable.name,
					value: recipientable.recipientable.id
				})
			}
			else if (recipientable.list_email) {
				inputValues.recipients.push({
					type: 'list',
					label: 'List: ' + recipientable.list_email,
					value: recipientable.list_email
				})
			}
		})
	}

	async fetchTemplateOptions() {
		const broadcastsService = new BroadcastsService()

		const templatesResponse = await broadcastsService.getTemplateOptions()
		let templateOptions = []
		templatesResponse.data.forEach((template) => {
			if (template.name !== 'test1') {
				templateOptions.push({
					label: template.name.charAt(0).toUpperCase() + template.name.slice(1),
					value: template.name,
				})
			}
		})
		templateOptions.push({
			label: 'None (Custom HTML)',
			value: null,
		})
		this.setState({
			templateOptions: templateOptions
		})
	}

	async fetchRecipientOptions() {
		const broadcastsService = new BroadcastsService()

		const recipientsResponse = await broadcastsService.getRecipientOptions()

		this.setState({
			recipientOptions: recipientsResponse.data
		})
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (name === 'template') {
				this.setState({ showTinymce: value.value !== null })
			}

			if (scope === 'recipients') {
				const prevValues = inputValues[scope][name];
				inputValues[scope][name] = {...value, ...{
					type: prevValues.type
				}}
			}
			else if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleBroadcastRecipientAdd = (type) => {
		// Get current form data from state
		const {inputValues} = this.state

		// add blank recipient value with correct type
		inputValues.recipients.push({
			type: type,
			value: ''
		})

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleBroadcastRecipientRemove = (key) => {
		// Get current form data from state
		const {inputValues} = this.state

		// remove recipient value from state
		inputValues.recipients.splice(key, 1)

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent = null) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {item, handleEditComplete} = this.props
		const {inputValues} = this.state
		const apiService = new ApiService()
		const {addNotification} = this.context

		// prepare form data
		if (inputValues['template']) { // deal with react-select object values
			inputValues['template'] = inputValues['template']['value']
		}
		const formData = JSON.stringify(inputValues)

		// submit user data
		await apiService.put(`broadcasts/${item.id}`, {body: formData})
			.then(async (response) => {
				if (response.success) {
					addNotification('Broadcast Updated', 'success')
					handleEditComplete(response.data)
				}
				else {
					const inputErrors = response.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminBroadcastsEdit
